<template>
  <div id="page-lap-pelayanan-harian">
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Dari
                  </template>
                  <b-form-datepicker
                    style="width: 100%"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    no-flip
                    locale="id"
                    v-model="data_search.tanggal_mulai"
                  ></b-form-datepicker>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Sampai
                  </template>
                  <b-form-datepicker
                    style="width: 100%"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    no-flip
                    locale="id"
                    v-model="data_search.tanggal_selesai"
                  ></b-form-datepicker>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kunjungan Lebih dr 5
                  </template>
                  <b-form-select
                    :options="option_lebih"
                    size="sm"
                    v-model="kunjungan_5"
                    @change="kunjungan_lebih(kunjungan_5)"
                  ></b-form-select>
                </b-form-group>
                <!-- <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Poli
                  </template>
                  <b-form-select
                    :options="options_poli"
                    size="sm"
                    v-model="data_search.ms_poli_id"
                    :disabled="disabled_field"
                  ></b-form-select>
                </b-form-group> -->
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kunjungan
                  </template>
                  <b-form-select
                    :options="option_kunjungan"
                    size="sm"
                    v-model="data_search.kunjungan_sakit"
                    :disabled="disabled_field"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Cara Masuk
                  </template>
                  <b-form-select
                    :options="option_cara_masuk"
                    size="sm"
                    v-model="data_search.tipe_pendaftaran"
                    :disabled="disabled_field"
                  ></b-form-select>
                </b-form-group>
                <!-- <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Status Periksa
                  </template>
                  <b-form-select
                    :options="option_status_periksa"
                    size="sm"
                    v-model="data_search.pendaftaran"
                  ></b-form-select>
                </b-form-group> -->
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Jenis Kelamin
                  </template>
                  <b-form-select
                    :options="option_jns_kelamin"
                    size="sm"
                    v-model="data_search.jenis_kelamin"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Asuransi
                  </template>
                  <b-form-select
                    :options="option_asuransi"
                    size="sm"
                    v-model="data_search.asuransi"
                    :disabled="disabled_field"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Umur
                  </template>
                  <div style="width:100%;display: flex;">
                    <b-form-input
                        type="number"
                        class="custom-input-text"
                        v-model="data_search.usia_tahun_mulai"
                    ></b-form-input>
                    <div class="custom-box-satuan">
                      <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                    </div>
                    <b-form-input
                        type="number"
                        class="custom-input-text"
                        v-model="data_search.usia_bulan_mulai"
                    ></b-form-input>
                    <div class="custom-box-satuan">
                      <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                    </div>
                    <b-form-input
                        type="number"
                        class="custom-input-text"
                        v-model="data_search.usia_hari_mulai"
                    ></b-form-input>
                    <div class="custom-box-satuan">
                      <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Sampai
                  </template>
                  <div style="width:100%;display: flex;">
                    <b-form-input
                        type="number"
                        class="custom-input-text"
                        v-model="data_search.usia_tahun_selesai"
                    ></b-form-input>
                    <div class="custom-box-satuan">
                      <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                    </div>
                    <b-form-input
                        type="number"
                        class="custom-input-text"
                        v-model="data_search.usia_bulan_selesai"
                    ></b-form-input>
                    <div class="custom-box-satuan">
                      <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                    </div>
                    <b-form-input
                        type="number"
                        class="custom-input-text"
                        v-model="data_search.usia_hari_selesai"
                    ></b-form-input>
                    <div class="custom-box-satuan">
                      <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Kecamatan
                  </template>
                  <multiselect
                    :options="options_kecamatan"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    label="nama_kecamatan"
                    size="sm"
                    v-model="data_search.kecamatan_id"
                    @input="getKelurahan(data_search.kecamatan_id)"
                  ></multiselect>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Kelurahan/Desa
                  </template>
                  <multiselect
                    id=""
                    :options="options_kelurahan"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    label="nama_kelurahan"
                    v-model="data_search.kelurahan_id"
                  ></multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Diagnosa
                  </template>
                  <multiselect
                    :options="options_diagnosa"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    label="nama_diagnosa"
                    size="sm"
                    v-model="data_search.diagnosa_id"
                    :disabled="disabled_field"
                  ></multiselect>
                </b-form-group>
                <!-- <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tenaga Medis
                  </template>
                  <multiselect
                    :options="options_tenaga_medis"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    label="nama_tenaga_medis"
                    size="sm"
                    v-model="data_search.tenaga_medis"
                    :disabled="disabled_field"
                  ></multiselect>
                </b-form-group> -->
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tindakan
                  </template>
                  <multiselect
                    :options="options_tindakan"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    label="nama_tindakan"
                    size="sm"
                    v-model="data_search.tindakan_id"
                    :disabled="disabled_field"
                  ></multiselect>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Obat
                  </template>
                  <multiselect
                    :options="options_barang"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    label="nama_barang"
                    size="sm"
                    v-model="data_search.barang_id"
                    :disabled="disabled_field"
                  ></multiselect>
                </b-form-group>
              </b-col>
            </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <hr />
              <div style="width:100%;display:flex">
                <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                  <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                </div>
                <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                  <b-button variant="outline-primary" size="sm">
                    <b-form-checkbox
                      id="check_puskesmas"
                      name="check_puskesmas"
                      v-model="checkedPuskesmas"
                      @change="toggleSelectPuskesmas()"
                    >
                      <span class="pt-1">Pilih Semua</span>
                    </b-form-checkbox>
                  </b-button>
                </div>
              </div>
              <hr>
              <b-row style="padding-left:7.5px;margin-top:15px;">
                <b-col v-for="(item, idx) in listPuskesmas" :key="idx" cols="12" sm="12" md="6" lg="4" xl="4">
                    <b-form-checkbox  v-model="item.checked">
                      {{ item.nama_puskesmas }}
                    </b-form-checkbox>
                </b-col>
              </b-row>
              <hr />
            </b-col>
          </b-row>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="display:flex;justify-content:flex-start">
                  <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                  <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button>
                  <b-button variant="warning">Reset</b-button> -->
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h4 style="text-align:center"><strong>LAPORAN HARIAN - PELAYANAN PASIEN</strong></h4>
                <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ total }}</strong></h5>
              </b-col>
            </b-row>
            <!-- <b-row class="mt-4">
              <b-col md="9" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>
                  

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row> -->
            <b-row>
              <!-- <b-col v-if="!kunjungan_5" cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                <template #cell(diagnosa)="item">
                    <b-row>
                        <b-col>
                            <ul>
                                <li v-for="(itemnya, index) in item.item.diagnosa" :key="index">{{ itemnya.nm_diag }}</li>
                            </ul>
                        </b-col>
                    </b-row>
                </template>
                <template #cell(tindakan)="item">
                    <b-row>
                        <b-col>
                            <ul>
                                <li v-for="(itemnya, index) in item.item.tindakan" :key="index">{{ itemnya }}</li>
                            </ul>
                        </b-col>
                    </b-row>
                </template>
                <template #cell(resep)="item">
                    <b-row>
                        <b-col>
                            <ul>
                                <li v-for="(itemnya, index) in item.item.resep" :key="index">{{ itemnya.nama_barang }} {{ itemnya.qty_barang }} {{ itemnya.signa_racikan }}</li>
                            </ul>
                        </b-col>
                    </b-row>
                </template>
                </b-table>
              </b-col> -->
              <b-col v-if="!kunjungan_5" cols="12" md="12" lg="12">
                  <b-table-simple responsive class="table-lap-pelayanan-harian">
                      <b-thead>
                          <b-tr>
                              <b-th rowspan="2" style="position:sticky;left:0;min-width:50px;background-color: #fff;">No.</b-th>
                              <b-th rowspan="2" style="position:sticky;left:49px;min-width:120px;background-color: #fff;">Tanggal</b-th>
                              <b-th rowspan="2" style="position:sticky;left:120px;min-width:160px;background-color:#fff">Nama Pasien</b-th>
                              <b-th rowspan="2">No. eRM</b-th>
                              <b-th rowspan="2">NIK</b-th>
                              <b-th rowspan="2">Jenis Kelamin</b-th>
                              <b-th rowspan="2">Tempat & Tanggal Lahir</b-th>
                              <b-th rowspan="2">Umur</b-th>
                              <b-th rowspan="2">Pekerjaan</b-th>
                              <b-th rowspan="2">Alamat</b-th>
                              <b-th rowspan="2">Kelurahan</b-th>
                              <b-th rowspan="2">Nama Ayah</b-th>
                              <b-th rowspan="2">Kunjungan</b-th>
                              <b-th rowspan="2">Poli / Ruangan</b-th>
                              <b-th rowspan="2">Asuransi</b-th>
                              <b-th rowspan="2">No. Asuransi</b-th>
                              <b-th rowspan="2">Kelurahan</b-th>
                              <b-th rowspan="2">Dokter</b-th>
                              <b-th rowspan="2">Perawat</b-th>
                              <b-th class="text-center" colspan="3">SOAP</b-th>
                              <b-th class="text-center" colspan="3">Alergi</b-th>
                              <b-th rowspan="2">RPS</b-th>
                              <b-th rowspan="2">RPD</b-th>
                              <b-th rowspan="2">RPK</b-th>
                              <b-th rowspan="2">Kesadaran</b-th>
                              <b-th rowspan="2">Triage</b-th>
                              <b-th rowspan="2">Tinggi Badan</b-th>
                              <b-th rowspan="2">Berat Badan</b-th>
                              <b-th rowspan="2">Lingkar Perut</b-th>
                              <b-th rowspan="2">Sistole</b-th>
                              <b-th rowspan="2">Diastole</b-th>
                              <b-th rowspan="2">Nafas</b-th>
                              <b-th rowspan="2">Detak Jantung</b-th>
                              <b-th rowspan="2">Detak Nadi</b-th>
                              <b-th rowspan="2">Suhu</b-th>
                              <b-th rowspan="2">Skala Nyeri</b-th>
                              <b-th rowspan="2">IMT</b-th>
                              <b-th rowspan="2">Hasil IMT</b-th>
                              <b-th rowspan="2" style="min-width: 175px;">Diagnosa</b-th>
                              <b-th rowspan="2" style="min-width: 175px;">Tindakan</b-th>
                              <b-th rowspan="2" style="min-width: 175px;">Resep</b-th>
                          </b-tr>
                          <b-tr>
                              <b-th style="min-width: 175px;">Objective</b-th>
                              <b-th style="min-width: 175px;">Plan</b-th>
                              <b-th style="min-width: 175px;">Subjective</b-th>
                              <b-th style="min-width: 175px;">Makanan</b-th>
                              <b-th style="min-width: 175px;">Obat</b-th>
                              <b-th style="min-width: 175px;">Udara</b-th>
                          </b-tr>
                      </b-thead>
                      <b-tbody>
                          <b-tr v-for="(item, idx) in items" :key="idx">
                              <b-td style="position:sticky;left:0;min-width:50px;background-color: #fff;">{{ idx + 1 }}</b-td>
                              <b-td style="position:sticky;left:49px;min-width:120px;background-color: #fff;">{{ item.tgl }}</b-td>
                              <b-td style="position:sticky;left:120px;min-width:160px;background-color:#fff">{{ item.nama_lengkap }}</b-td>
                              <b-td>{{ item.no_rm }}</b-td>
                              <b-td>{{ item.nik }}</b-td>
                              <b-td>{{ item.jenis_kelamin }}</b-td>
                              <b-td>{{ item.ttl }}</b-td>
                              <b-td>{{ item.umur }}</b-td>
                              <b-td>{{ item.pekerjaan }}</b-td>
                              <b-td>{{ item.alamat_sekarang }}</b-td>
                              <b-td>{{ item.nama_kelurahan }}</b-td>
                              <b-td>{{ item.nama_ayah }}</b-td>
                              <b-td>{{ item.nama_poli }}</b-td>
                              <b-td>{{ item.no_rm }}</b-td>
                              <b-td>{{ item.nama_poli }}</b-td>
                              <b-td>{{ item.asuransi }}</b-td>
                              <b-td>{{ item.no_kartu }}</b-td>
                              <b-td>{{ item.nama_dokter }}</b-td>
                              <b-td>{{ item.nama_perawat }}</b-td>
                              <b-td v-if="item.data_assesment_soap">
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.data_assesment_soap.objective" :key="index">{{ obj }}</li>
                                </ul>
                              </b-td>
                              <b-td v-else>-</b-td>
                              <b-td v-if="item.data_assesment_soap">
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.data_assesment_soap.plan" :key="index">{{ obj }}</li>
                                </ul>
                              </b-td>
                              <b-td v-else>-</b-td>
                              <b-td v-if="item.data_assesment_soap">
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.data_assesment_soap.subjective" :key="index">{{ obj }}</li>
                                </ul>
                              </b-td>
                              <b-td v-else>-</b-td>
                              <b-td>
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.alergi[0].makanan" :key="index">{{ obj.nama_alergi }}</li>
                                </ul>
                              </b-td>
                              <b-td>
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.alergi[0].obat" :key="index">{{ obj.nama_alergi }}</li>
                                </ul>
                              </b-td>
                              <b-td>
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.alergi[0].udara" :key="index">{{ obj.nama_alergi }}</li>
                                </ul>
                              </b-td>
                              <b-td>{{ item.rps }}</b-td>
                              <b-td>{{ item.rpd }}</b-td>
                              <b-td>{{ item.rpk }}</b-td>
                              <b-td>{{ item.nama_sadar }}</b-td>
                              <b-td>{{ item.triage }}</b-td>
                              <b-td>{{ item.tinggi_badan }}</b-td>
                              <b-td>{{ item.berat_badan }}</b-td>
                              <b-td>{{ item.lingkar_perut }}</b-td>
                              <b-td>{{ item.sistole }}</b-td>
                              <b-td>{{ item.diastole }}</b-td>
                              <b-td>{{ item.nafas }}</b-td>
                              <b-td>{{ item.detak_jantung }}</b-td>
                              <b-td>{{ item.detak_nadi }}</b-td>
                              <b-td>{{ item.suhu }}</b-td>
                              <b-td>{{ item.skala_nyeri }}</b-td>
                              <b-td>{{ item.imt }}</b-td>
                              <b-td>{{ item.hasil_imt }}</b-td>
                              <b-td>
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.diagnosa" :key="index">{{ obj.nm_diag }}</li>
                                </ul>
                              </b-td>
                              <b-td>
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.tindakan" :key="index">{{ obj }}</li>
                                </ul>
                              </b-td>
                              <b-td>
                                <ul style="padding-left: 15px;">
                                  <li v-for="(obj, index) in item.resep" :key="index">{{ obj.nama_barang }} {{ obj.qty_barang }} {{ obj.signa_racikan }}</li>
                                </ul>
                              </b-td>
                          </b-tr>
                      </b-tbody>
                  </b-table-simple>
              </b-col>

              <b-col v-else cols="12" md="12" lg="12">
                <b-table
                  :items="items_lebih_5"
                  :fields="fields_lebih_5"
                  responsive
                  show-empty
                  small
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                </b-table>
              </b-col>
            </b-row>

            <!-- <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6" offset-md="3">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row> -->
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
  
<script>
  import Multiselect from "vue-multiselect";
  export default {
    name: "kunjungan_pasien",
    components: {
      Multiselect
    },
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        kunjungan_5: false,
        total: null,
        data_search: {
          tanggal_mulai: null,
          tanggal_selesai: null,
          kunjungan_sakit: null,
          jenis_kelamin: null,
          usia_tahun_mulai: null,
          usia_bulan_mulai: null,
          usia_hari_mulai: null,
          usia_tahun_selesai: null,
          usia_bulan_selesai: null,
          usia_hari_selesai: null,
          asuransi: null,
          kecamatan_id: null,
          kelurahan_id: null,
          diagnosa_id: null,
          tindakan_id: null,
          barang_id: null,
          ms_poli_id: null,
          tenaga_medis: null,
          ms_diagnosa_id: null,
          ms_kecamatan_id: null,
          ms_kelurahan_id: null,
          tenaga_medis_id: null,
          ms_tindakan_id: null,
          ms_barang_id: null,
          halaman: 0,
          jumlah: 1000,
          tipe_pendaftaran: null,
        },
        checkedPuskesmas: false,
        listPuskesmas: [],
        options_kecamatan: [],
        options_kelurahan: [],
        options_diagnosa: [],
        options_tindakan: [],
        options_tenaga_medis: [],
        options_barang: [],
        options_poli: [],
        option_kunjungan: [
          {value: null, text: "Semua"},
          {value: "true", text: "Sakit"},
          {value: "false", text: "Sehat"},
        ],
        option_lebih: [
          {value: false, text: "Tidak"},
          {value: true, text: "Ya"},
        ],
        option_jns_kelamin: [
          {value: null, text: "Semua"},
          {value: "L", text: "Laki-laki"},
          {value: "P", text: "Perempuan"},
        ],
        option_cara_masuk: [
          {value: null, text: "Semua"},
          {value: "Pendaftaran", text: "Pendaftaran"},
          {value: "Rujuk Internal", text: "Rujuk Internal"},
        ],
        option_asuransi: [
          {value: null, text: "Semua"},
          {value: "UMUM", text: "Umum"},
          {value: "BPJS", text: "BPJS Kesehatan"},
        ],

        fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "tgl",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          // stickyColumn: true,
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-nama text-left",
          // stickyColumn: true,
        },
        {
          key: "no_rm",
          label: "No. eRM",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nik",
          label: "NIK",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "noDokRm",
        //   label: "No. Dokumen RM",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "jenis_kelamin",
          label: "Jenis Kelamin",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },

        {
          key: "ttl",
          label: "Tempat & Tanggal Lahir",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-ttl text-left",
        },

        {
          key: "umur",
          label: "Umur",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-umur text-left",
        },

        {
          key: "pekerjaan",
          label: "Pekerjaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "alamat_sekarang",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-alamat text-left",
        },

        {
          key: "nama_kelurahan",
          label: "Kelurahan",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-umur text-left",
        },

        {
          key: "nama_ayah",
          label: "Nama Ayah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        // {
        //   key: "kunjungan_sakit",
        //   label: "Jenis Kunjungan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "kunjungan",
          label: "Kunjungan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "nama_poli",
          label: "Poli / Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "asuransi",
          label: "Asuransi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "no_kartu",
          label: "No. Asuransi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "diagnosa",
          label: "Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "tindakan",
          label: "Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "resep",
          label: "Resep",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        
      ],
      fields_lebih_5: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "tgl",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-nama text-left",
        },
        {
          key: "no_rm",
          label: "No. eRM",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nik",
          label: "NIK",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "jenis_kelamin",
          label: "Jenis Kelamin",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },

        {
          key: "ttl",
          label: "Tempat & Tanggal Lahir",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-ttl text-left",
        },

        {
          key: "umur",
          label: "Umur",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-umur text-left",
        },

        {
          key: "pekerjaan",
          label: "Pekerjaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "alamat_sekarang",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-alamat text-left",
        },

        {
          key: "nama_ayah",
          label: "Nama Ayah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "jumlah_pelayanan",
          label: "Jumlah Pelayanan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        
      ],
      items: [],
      items_lebih_5: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
      disabled_field: false,
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        // console.log(this.fields.map(el => el.key))
        return this.fields.map(el => el.key)
      },
    },
    activated(){
      this.totalRows = this.items.length;
      this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.getDataKunjungan();
      this.getData()
      this.getKecamatan()
      this.getPuskesmas()
    },
    // async mounted() {
    //   // Set the initial number of items
    //   this.totalRows = this.items.length;
    //   this.getDatas();
    // },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async toggleSelectPuskesmas () {
        const vm = this
        await new Promise(resolve => setTimeout(resolve, 1));
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          const data = vm.listPuskesmas[i];
          data.checked = vm.checkedPuskesmas;
        }
        vm.$forceUpdate()
      },
      async getPuskesmas(){
        const vm = this
        vm.tableBusy = false
        try {
          const listPuskesmas = await vm.$axios.post('/puskesmas/list')
          vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
        } catch (error) {
          console.log(error)
        } finally {
          vm.tableBusy = false
        }
      },
      kunjungan_lebih(x){
        let vm = this
        if(x == true){
          vm.disabled_field = true
          vm.data_search.diagnosa_id = null
          vm.data_search.asuransi = null
          vm.data_search.tindakan_id = null
          vm.data_search.barang_id = null
          vm.data_search.tenaga_medis_id = null
          vm.data_search.kunjungan_sakit = null
          vm.data_search.ms_poli_id = null
          vm.data_search.tipe_pendaftaran = null
        }else {
          vm.disabled_field = false
        }
      },
      async getData(){
        let vm = this
        let diagnosa = await vm.$axios.post("/ms_diagnosa/list")
        if(diagnosa.data.data.length){
          vm.options_diagnosa = diagnosa.data.data
          for (let i = 0; i < vm.options_diagnosa.length; i++) {
            let x = vm.options_diagnosa[i];
            x.nama_diagnosa = `${x.kd_diag} - ${x.nm_diag}`
          }
        }
        // let poli = await vm.$axios.post("/ms_poli/list")
        // if(poli.data.data.length){
        //   vm.options_poli = [{value:null, text: "Semua"}]
        //   for (let i = 0; i < poli.data.data.length; i++) {
        //     let x = poli.data.data[i];
        //     let data = {}
        //     data.value = x.ms_poli_id
        //     data.text = x.nama_poli
        //     vm.options_poli.push(data)
        //   }
        // }
        let tindakan = await vm.$axios.post("/ms_tindakan/list")
        if(tindakan.data.data.length){
            vm.options_tindakan = tindakan.data.data
        }
        let tenaga_medis = await vm.$axios.post("/tenaga_medis/list")
        if(tenaga_medis.data.data.length){
          vm.options_tenaga_medis = tenaga_medis.data.data
        }
        let listBarang = await vm.$axios.post('/ms_barang/list')
        if(listBarang.data.data.length){
          vm.options_barang = listBarang.data.data
        }
      },
      async getKecamatan(){
        let vm = this
        let kecamatan = await vm.$axios.post("/ms_kecamatan/list")
        vm.options_kecamatan = kecamatan.data.data
      },
      async getKelurahan(x){
        let vm = this
        vm.data_search.kelurahan_id = null
        let kelurahan = await vm.$axios.post("/ms_kelurahan/list", {ms_kecamatan_id: x.ms_kecamatan_id})
        vm.options_kelurahan = kelurahan.data.data
      },
      getDataKunjungan(){
        let vm = this
        //puskesmas
        const bulk_puskesmas_id = []
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          const puskesmas = vm.listPuskesmas[i];
          if(puskesmas.checked){
            bulk_puskesmas_id.push(puskesmas.id)
          }
        }
        vm.data_search.bulk_puskesmas_id = bulk_puskesmas_id
        if(vm.kunjungan_5 == true){
            if(vm.data_search.kecamatan_id){
                vm.data_search.ms_kecamatan_id = vm.data_search.kecamatan_id.ms_kecamatan_id
            }
            if(vm.data_search.kelurahan_id){
                vm.data_search.ms_kelurahan_id = vm.data_search.kelurahan_id.ms_kelurahan_id
            }
            if(vm.data_search.diagnosa_id){
                vm.data_search.ms_diagnosa_id = vm.data_search.diagnosa_id.ms_diagnosa_id
            }
            if(vm.data_search.barang_id){
                vm.data_search.ms_barang_id = vm.data_search.barang_id.ms_barang_id
            }
            if(vm.data_search.tindakan_id){
                vm.data_search.ms_tindakan_id = vm.data_search.tindakan_id.ms_tindakan_id
            }
            if(vm.data_search.tenaga_medis){
                vm.data_search.tenaga_medis_id = vm.data_search.tenaga_medis_id.tenaga_medis_id
            }
            vm.$axios.post("/kunjungan/list_laporan_pelayanan_pasien_kunjungan_lebih_dari_5", vm.data_search)
            .then((res) => {
                console.log(res, 'ini respon');
                  vm.items_lebih_5 = res.data.data
                  vm.total = res.data.data.length
                  for (let i = 0; i < vm.items_lebih_5.length; i++) {
                    let x = vm.items_lebih_5[i];
                    x.no = i+1
                    x.tgl = vm.$moment(x.tanggal_daftar).format("DD-MM-YYYY HH:mm")
                    x.ttl = `${x.tempat_lahir}, ${vm.$moment(x.tanggal_lahir).format("DD-MM-YYYY")}`
                    x.umur = `${x.usia_tahun} tahun ${x.usia_bulan} bulan ${x.usia_hari} hari`
                    if(x.kunjungan_sakit == true){
                      x.kunjungan = "Sakit" 
                    }else {
                      x.kunjungan = "Sehat"
                    }
                  }
                  vm.totalRows = vm.items.length
            })
            .catch((err) => {
                console.log(err);
            })
        }else {
            if(vm.data_search.kecamatan_id){
                vm.data_search.ms_kecamatan_id = vm.data_search.kecamatan_id.ms_kecamatan_id
            }
            if(vm.data_search.kelurahan_id){
                vm.data_search.ms_kelurahan_id = vm.data_search.kelurahan_id.ms_kelurahan_id
            }
            if(vm.data_search.diagnosa_id){
                vm.data_search.ms_diagnosa_id = vm.data_search.diagnosa_id.ms_diagnosa_id
            }
            if(vm.data_search.barang_id){
                vm.data_search.ms_barang_id = vm.data_search.barang_id.ms_barang_id
            }
            if(vm.data_search.tindakan_id){
                vm.data_search.ms_tindakan_id = vm.data_search.tindakan_id.ms_tindakan_id
            }
            if(vm.data_search.tenaga_medis_id){
                vm.data_search.tenaga_medis_id = vm.data_search.tenaga_medis_id.tenaga_medis_id
            }
            vm.$axios.post("/kunjungan/list_laporan_pelayanan_pasien", vm.data_search)
            .then((res) => {
                console.log(res, 'ini respon');
                  vm.items = res.data.data
                  vm.total = res.data.data.length
                  for (let i = 0; i < vm.items.length; i++) {
                    let x = vm.items[i];
                    x.no = i+1
                    x.tgl = vm.$moment(x.tanggal_daftar).format("DD-MM-YYYY HH:mm")
                    x.ttl = `${x.tempat_lahir}, ${vm.$moment(x.tanggal_lahir).format("DD-MM-YYYY")}`
                    x.umur = `${x.usia_tahun} tahun ${x.usia_bulan} bulan ${x.usia_hari} hari`
                    if(x.kunjungan_sakit == true){
                      x.kunjungan = "Sakit" 
                    }else {
                      x.kunjungan = "Sehat"
                    }
                  }
                  vm.totalRows = vm.items.length
            })
            .catch((err) => {
                console.log(err);
            })
        }
        
      },
      
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  };
  </script>
  <style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* #page-lap-pelayanan-harian .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  } */

  #page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
    background-color: #D52F65 !important ;
    color: #fff;
    font-weight: 600;
  }

  #page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
    border: 1px solid #F1F1F1 !important;
  }

  #page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
    border-top-left-radius: 8px;
  }

  #page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
    border-top-right-radius: 8px;
  }
  </style>
  